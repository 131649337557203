// config.js

const bizIcon = 'https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/UHvLlBBKg1OVdWcyKtW9/media/6744b4443557a77687d1bd74.jpeg';
const myBizIcon = 'https://mybizsolutions.us/static/media/icon-app-logo.83ff8bf39a11df9fb7ac.jpg';
const imageOne = "https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/UHvLlBBKg1OVdWcyKtW9/media/6744bd5e5fa73d170487173f.jpeg";
const imageTwo = "https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/UHvLlBBKg1OVdWcyKtW9/media/6744bd5e5fa73d170487173f.jpeg";
const imageThree = "https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/UHvLlBBKg1OVdWcyKtW9/media/6744bd8d01f219990d714af9.jpeg";
const imageFour = "https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/UHvLlBBKg1OVdWcyKtW9/media/6744bdf56d4aa9fdf8140bea.jpeg";
const imageFive = "https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/UHvLlBBKg1OVdWcyKtW9/media/6744bf4f6ebc30301b89e4d9.jpeg";

const redirectLink = "https://chameleonpainting.net/";

export const siteConfig = {
  title: "Chameleon Painting",
  metaDescription: "Chameleon Painting",
  theme: {
    primaryColor: "blue-600",
    secondaryColor: "gray-900",
    accentColor: "text-blue-600"
  },
  logo: {
    src: bizIcon,
    alt: "Chameleon Painting",
  },
  navigation: [
    { name: "Services", sectionId: "services" },
    { name: "About", sectionId: "about" },
    { name: "Location", sectionId: "location" },
  ],
  businessDetails: {
    name: "Chameleon Painting",
    headline: "Your Trusted Painting Experts in Everett, WA",
    address: "1622 E Marine View Dr h101 Everett, WA 98201, USA",
    description: `
        Chameleon Painting specializes in delivering exceptional residential and commercial painting services throughout Everett, Snohomish, and King Counties. With years of experience, we focus on precision, quality, and customer satisfaction. From exterior transformations to detailed interior work, our expert team ensures your property reflects your unique style. Trust Chameleon Painting to bring vibrant colors and professional finishes to your spaces.`,
  },
  images: {
    business: {
      src: imageOne,
      alt: "Your Trusted Painting Experts in Everett, WA",
      width: 2432,
      height: 1442
    }
  },
  features: [
    {
        description: 
          "Transformative Painting Services: Chameleon Painting elevates homes and businesses with vibrant, professional painting that brings your vision to life.",
    },
    {
        description: 
          "Attention to Detail: Our skilled team ensures precision and quality in every brushstroke, creating flawless finishes that stand the test of time.",
    },
    {
        description: 
          "Customized Solutions: We tailor our services to your unique needs, whether it's a bold new look for your interior or a refreshed exterior that stands out.",
    },
  ],
  services: {
    sectionTitle: "Services",
    description: 
    "Chameleon Painting proudly serves Everett, WA, and the surrounding areas with expert painting services tailored to meet the unique needs of homeowners and businesses. From transforming interiors to revitalizing exteriors, we deliver exceptional results with a focus on precision, quality, and customer satisfaction.",
    callouts: [
        {
          name: 'Homes & Condos',
          description: 
          "Enhance your home or condo with our professional painting services. We bring your vision to life, creating personalized, beautiful spaces with vibrant colors and flawless finishes.",
          imageSrc: imageTwo,
          imageAlt: 'Expert painters working on a home in Everett, WA',
        },
        {
          name: 'Buildings & Offices',
          description: 
          "Upgrade your commercial space with our high-quality painting solutions. Chameleon Painting ensures a polished, professional look that elevates your business environment.",
          imageSrc: imageThree,
          imageAlt: 'Commercial office being painted by Chameleon Painting in Everett, WA',
        },
        {
          name: 'Cabinet Repainting',
          description: 
          "Give your cabinets a fresh, modern look with our expert repainting services. We deliver durable, flawless finishes that transform your space into something extraordinary.",
          imageSrc: imageFour,
          imageAlt: 'Cabinets beautifully repainted in a kitchen in Everett, WA',
        },
    ],
    otherServices: [
      "Pressure Washing",
      "Roof Cleaning",
      "Gutter Cleaning",
      "General Landscapings",
    ]
  },
  about: {
    sectionTitle: "About",
    description:   
      "With over 8 years of experience, Chameleon Painting has transformed over 400 premises and brought smiles to 45+ happy clients in Snohomish and King Counties. We take pride in delivering exceptional painting services with a commitment to quality, precision, and customer satisfaction. From homes and condos to offices and cabinets, we ensure every brushstroke adds value and beauty to your space.",
    image: {
      src: imageFive,
      alt: "Chameleon Painting team transforming a property in Everett, WA",
      width: 1200,
      height: 800
    }
  },
  location: {
    sectionTitle: "Our Location",
    destinationAddress: "Everett, WA, United States",
    googleMapsEmbedUrl: 
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2689.393465913718!2d-122.21053148437163!3d47.97958437921198!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x549aa005fdf38d2d%3A0xabcdef123456789!2sEverett%2C%20WA%2C%20USA!5e0!3m2!1sen!2sus!4v1710407450828!5m2!1sen!2sus",
    subCategories: [
      { 
        name: 'Apple Maps', 
        url: 'https://maps.apple.com/?address=Everett,%20WA,%20USA&ll=47.979584,-122.210531&q=Everett',
      },
      { 
        name: 'Bing Maps', 
        url: 'https://www.bing.com/maps?q=Everett,+WA,+USA&FORM=HDRSC6',
      },
      { 
        name: 'Google Earth', 
        url: 'https://earth.google.com/web/search/Everett,+WA,+USA/@47.979584,-122.210531,15a,814.57589625d,35y,0h,0t,0r',
      },
      { 
        name: 'Google Maps', 
        url: 'https://www.google.com/maps/place/Everett,+WA,+USA/@47.979584,-122.210531,15z',
      }
    ]
  },
  contact: {
    sectionTitle: "Get in Touch",
    description: 
      "We would love to help bring your painting vision to life! Whether you need a quote, have questions about our services, or want to schedule an appointment, our team is here to assist.",
    socialLinks: {
      facebook: redirectLink,     
      instagram: redirectLink,     
      twitter: redirectLink,     
      linkedin: redirectLink,     
      youtube: redirectLink,     
    },
    contactNumbers: [
      "Mobile: (425) 528-3221",
    ],
    logo: bizIcon,
    emailRecipient: "chameleonpainting24@gmail.com"
  },
  footer: {
    bizSolutionsLogo: myBizIcon
  }
};


